import React from "react";
import DatePicker from "react-datepicker";
import { subDays, isAfter, isBefore, isEqual } from "date-fns";
import * as firebase from "firebase/app";

import MainWrapper from "../components/main-wrapper";
import {
  FilterContent,
  FilterItem,
  FilterLabel,
  DataTable,
  DataLabel,
  DataTableHeading,
  TitleContainer,
  PastReferralWrapper,
  TitleText,
  DataRow,
  DataItem,
  DataContent
} from "../styles/referrals";
import { getReferrals } from "../utility/database";
import moment from "moment";
import { STATUS_TYPES } from "../utility/constants";
import {
  DashboardNoReferralTopContent,
  DashboardReferralTopLoader
} from "../styles/dashboard";
import { STATUS_CODES } from "http";

const ASC = "↑";
const DESC = "↓";

const SORT_BY = {
  NONE: "NONE",
  NAME_ASC: "NAME_ASC",
  NAME_DESC: "NAME_DESC",
  SUBMISSION_DATE_ASC: "SUBMISSION_DATE_ASC",
  SUBMISSION_DATE_DESC: "SUBMISSION_DATE_DESC"
};

const handleDateChange = setter => date => {
  setter(date);
};

const filterByDateRange = (from, to, data) => {
  if (from && to && data.length) {
    const rangeData = [];
    data.forEach(referral => {
      const referralSubmissionDate = new Date(
        referral.submittedOn.seconds * 1000
      );
      const liesInRange =
        !isAfter(referralSubmissionDate, to) &&
        !isBefore(referralSubmissionDate, from);
      if (liesInRange) {
        rangeData.push(referral);
      }
    });
    return rangeData;
  }
  return data;
};

export default function PastReferrals() {
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = React.useState(new Date());
  const [originalData, setOriginalData] = React.useState([]);
  const [referrals, setReferrals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [sortBy, setSortBy] = React.useState(SORT_BY.NONE);

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const currentUser = global.AUTH.currentUser;
      if (currentUser) {
        const { email } = currentUser;
        let data = await getReferrals(email);
        data = data.filter(
          ({ status }) =>
            status === STATUS_TYPES.SUBMITTED_TO_UNDERWRITING ||
            status === STATUS_TYPES.QUOTE_AVAILABLE
        );
        setOriginalData(data);
        const filteredData = filterByDateRange(startDate, endDate, data);
        setReferrals(filteredData);
        setLoading(false);
        setSortBy(SORT_BY.NONE);
      }
    };

    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetchData();
      }
    });
  }, [endDate, startDate]);

  // React.useEffect(() => {
  //   if (referrals.length > 0 && !loading) {
  //     setLoading(true);
  //     const sortedData = [...referrals];
  //     switch (sortBy) {
  //       case SORT_BY.NAME_ASC:
  //         sortedData.sort(function(ref1, ref2) {
  //           const name1 = `${ref1.firstName} ${ref1.lastName}`;
  //           const name2 = `${ref2.firstName} ${ref2.lastName}`;
  //           if (name1 < name2) {
  //             return -1;
  //           }
  //           if (name1 > name2) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         break;
  //       case SORT_BY.NAME_DESC:
  //         sortedData.sort(function(ref1, ref2) {
  //           const name1 = `${ref1.firstName} ${ref1.lastName}`;
  //           const name2 = `${ref2.firstName} ${ref2.lastName}`;
  //           if (name1 < name2) {
  //             return 1;
  //           }
  //           if (name1 > name2) {
  //             return -1;
  //           }
  //           return 0;
  //         });
  //         break;
  //       case SORT_BY.SUBMISSION_DATE_ASC:
  //         sortedData.sort(function(ref1, ref2) {
  //           const date1 = new Date(ref1.submittedOn.seconds * 1000);
  //           const date2 = new Date(ref2.submittedOn.seconds * 1000);
  //           if (date1 < date2) {
  //             return -1;
  //           }
  //           if (date1 > date2) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         break;
  //       case SORT_BY.SUBMISSION_DATE_DESC:
  //         sortedData.sort(function(ref1, ref2) {
  //           const date1 = new Date(ref1.submittedOn.seconds * 1000);
  //           const date2 = new Date(ref2.submittedOn.seconds * 1000);
  //           if (date1 < date2) {
  //             return 1;
  //           }
  //           if (date1 > date2) {
  //             return -1;
  //           }
  //           return 0;
  //         });
  //         break;
  //       case SORT_BY.NONE:
  //       default:
  //         break;
  //     }
  //     setReferrals(sortedData);
  //     setLoading(false);
  //   }
  // }, [sortBy, referrals]);

  // React.useEffect(() => {
  //   if (originalData.length > 0 && !loading) {
  //     const isProper =
  //       isAfter(endDate, startDate) || isEqual(startDate, endDate);
  //     if (isProper) {
  //       setLoading(true);
  //       const filteredData = filterByDateRange(
  //         startDate,
  //         endDate,
  //         originalData
  //       );
  //       setReferrals(filteredData);
  //       setLoading(false);
  //       setSortBy(SORT_BY.NONE);
  //     }
  //   }
  // }, [startDate, endDate, originalData]);

  const toggleNameSorting = () => {
    if ([SORT_BY.NAME_DESC, SORT_BY.NONE].includes(sortBy)) {
      setSortBy(SORT_BY.NAME_ASC);
    } else {
      setSortBy(SORT_BY.NAME_DESC);
    }
  };

  const toggleDateSorting = () => {
    if ([SORT_BY.SUBMISSION_DATE_DESC, SORT_BY.NONE].includes(sortBy)) {
      setSortBy(SORT_BY.SUBMISSION_DATE_ASC);
    } else {
      setSortBy(SORT_BY.SUBMISSION_DATE_DESC);
    }
  };

  const isInitialLoading = originalData && originalData.length === 0 && loading;
  const isProcessing = originalData && originalData.length > 0 && loading;
  const noReferralsFromBE = !loading && originalData.length === 0;
  const noFilteredData =
    !loading && originalData.length > 0 && referrals.length === 0;

  return (
    <MainWrapper>
      <PastReferralWrapper>
        <TitleContainer>
          <TitleText>Past Referrals</TitleText>
          <FilterContent>
            <FilterItem>
              <FilterLabel>Start Date</FilterLabel>
              <DatePicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange(setStartDate)}
                className="date-ranges"
              />
            </FilterItem>
            <FilterItem>
              <FilterLabel>End Date</FilterLabel>
              <DatePicker
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange(setEndDate)}
                minDate={startDate}
                className="date-ranges"
              />
            </FilterItem>
          </FilterContent>
        </TitleContainer>
        {isInitialLoading && (
          <DashboardReferralTopLoader textAlign="center">
            Fetching Referrals, Please wait...
          </DashboardReferralTopLoader>
        )}
        {noReferralsFromBE && (
          <DashboardNoReferralTopContent textAlign="center">
            No referrals found.
          </DashboardNoReferralTopContent>
        )}
        {originalData && originalData.length > 0 && (
          <DataTable>
            <DataTableHeading>
              <DataLabel>
                Name{" "}
                <span onClick={toggleNameSorting}>
                  {SORT_BY.NAME_ASC === sortBy ? DESC : ASC}
                </span>
              </DataLabel>
              <DataLabel>Approval Form</DataLabel>
              <DataLabel>
                Submission Date{" "}
                <span onClick={toggleDateSorting}>
                  {sortBy === SORT_BY.SUBMISSION_DATE_ASC ? DESC : ASC}
                </span>
              </DataLabel>
            </DataTableHeading>
            <DataContent>
              {isProcessing && (
                <DashboardReferralTopLoader textAlign="center">
                  Fetching Referrals, Please wait...
                </DashboardReferralTopLoader>
              )}
              {!noFilteredData ? (
                referrals.map(
                  (
                    { firstName, lastName, approvalType, submittedOn },
                    index
                  ) => (
                    <DataRow key={`name-${index}`}>
                      <DataItem>
                        {firstName} {lastName}
                      </DataItem>
                      <DataItem textTransform="capitalize">
                        {approvalType}
                      </DataItem>
                      <DataItem>
                        {submittedOn
                          ? moment(submittedOn.seconds * 1000).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </DataItem>
                    </DataRow>
                  )
                )
              ) : (
                <DashboardNoReferralTopContent textAlign="center">
                  No referrals found between the given dates, please change date
                  range.
                </DashboardNoReferralTopContent>
              )}
            </DataContent>
          </DataTable>
        )}
      </PastReferralWrapper>
    </MainWrapper>
  );
}
