import React from "react";
import StyledMainWrapper from "../styles/main-wrapper";

export default function MainWrapper({
  children,
  extraStyles = "",
  mobileStyles = ""
}) {
  return (
    <div>
      <StyledMainWrapper extraStyles={extraStyles} mobileStyles={mobileStyles}>
        {children}
      </StyledMainWrapper>
    </div>
  );
}
