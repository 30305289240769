import styled from "styled-components";
import { NICEBLUE, NASTYGREEN, BROWNISHGREY, GRAY, WHITE } from "./colors";
import MainWrapper from "./main-wrapper";
import { MOBILE } from "./constants";
import FlexBox from "./flexbox";
import Button from "./button";

export const WrapperExtended = styled(MainWrapper)`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: ${MOBILE}px) {
    width: 300px;
  }
`;

export const TitleContainer = styled(FlexBox)`
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: ${MOBILE}px) {
    justify-content: center;
  }
`;

export const TypographyWrapper = styled.div`
  flex: 1;
`;

export const DetailTitle = styled.div`
  color: ${NASTYGREEN};
  font-size: 16px;
  font-style: italic;
  margin: 10px 0 20px;
`;

export const TypographyTitle = styled.div`
  font-size: 23px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${NICEBLUE};
  @media (max-width: ${MOBILE}px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const TypographySubTitle = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${NASTYGREEN};
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Icon = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "50px" }) => height};
  width: ${({ width = "50px" }) => width};
  margin-right: 15px;
  object-fit: contain;
  @media (max-width: ${MOBILE}px) {
    height: 35px;
    width: 35px;
    margin: 0 15px 0 0;
  }
`;

export const DetailsContainer = styled.div``;

export const DetailRow = styled(FlexBox)`
  padding: 10px 0;
  align-items: ${({ defaultAlign = false }) =>
    defaultAlign ? "stretch" : "center"};
`;

export const DetailLabel = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: ${BROWNISHGREY};
  margin-right: 10px;
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
  }
`;

export const Detail = styled(FlexBox)`
  align-items: center;
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
  }
`;

export const Loader = styled.div`
  width: 900px;
  text-align: center;
  padding: 20px 0;
  margin 0 auto;
  color: ${BROWNISHGREY}
`;

export const DatePickerWrapper = styled.div`
  flex: 0 0 auto;
  width: ${({ width = "100%" }) => width};
  & > div {
    width: 100%;
  }
  & input {
    color: ${NASTYGREEN};
    padding: 10px 15px;
    border: 1px solid ${GRAY};
    border-radius: 0;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const CalendarIcon = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "15px" }) => height};
  width: ${({ width = "15px" }) => width};
  margin-left: 15px;
  object-fit: contain;
  @media (max-width: ${MOBILE}px) {
    display: none;
  }
`;

export const InputCheck = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
    line-height: 18px;
    display: inline-block;
    color: rgb(100, 100, 100);
    font-size: 16px;
    width: 100%;
  }
  &:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid ${NASTYGREEN};
    border-radius: 100%;
    background: #fff;
  }
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #d2cece;
    border-radius: 100%;
    background: #fff;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: ${NASTYGREEN};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const RadioLabel = styled.label`
  flex: 0 0 auto;
  width: ${({ width = "50%" }) => `${width}`};
  box-sizing: border-box;
  font-size: 16px;
  color: #787878;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const RadioContainer = styled(FlexBox)`
  align-items: center;
`;

export const Radio = styled(FlexBox)`
  align-items: center;
  margin-right: 15px;
`;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  margin-top: 10px;
  border: 1px solid rgb(160, 160, 160);
  outline: none;
`;
export const ButtonWrapper = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
`;

export const SubmitButton = styled(Button)`
  background-color: ${NASTYGREEN};
  padding: 16px;
  width: 200px;
  color: ${WHITE};
  text-transform: uppercase;
  margin-top: 18px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;
