import React from "react";
import MainWrapper from "../components/main-wrapper";
import Typography from "../styles/typography";
import { NICEBLUE, BROWNISHGREY } from "../styles/colors";

export default function ThankYou() {
  return (
    <MainWrapper>
      <Typography display='block' fontSize={36} color={NICEBLUE}>
        Thank you!
      </Typography>
      <Typography
        display='block'
        fontSize={20}
        color={BROWNISHGREY}
        margin='30px 0 0 '
      >
        We will have a homeowner’s insurance quote for you soon.
      </Typography>
    </MainWrapper>
  );
}
