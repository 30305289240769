import styled from "styled-components";
import { PRIMARY_FONT } from "./fonts";
import { MOBILE } from "./constants";
import { BROWNISHGREY } from "./colors";

const Typography = styled.span`
  white-space: ${({ whiteSpace = "pre-wrap" }) => whiteSpace};
  font-family: ${({ fontFamily = PRIMARY_FONT }) => fontFamily};
  font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  color: ${({ color = BROWNISHGREY }) => color};
  text-align: ${({ textAlign = "left" }) => textAlign};
  line-height: ${({ lineHeight = 1.33 }) => lineHeight};
  letter-spacing: ${({ letterSpacing = 0.1 }) => letterSpacing};
  margin: ${({ margin = 0 }) => margin};
  display: ${({ display = "inline" }) => display};
  cursor: ${({ cursor = "default" }) => `${cursor}`};
  padding: ${({ padding = 0 }) => padding};
  @media (max-width: ${MOBILE}px) {
    font-size: ${({ mobileFontSize, fontSize }) =>
      `${mobileFontSize || fontSize}px`};
    padding: ${({ mobilePadding, padding = 0 }) =>
      `${mobilePadding || padding}`};
    text-align: ${({ mobileTextAlign, textAlign = "left" }) =>
      mobileTextAlign || textAlign};
  }
`;

export default Typography;
