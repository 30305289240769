import styled from "styled-components";
import { NICEBLUE, BROWNISHGREY, WHITE, NASTYGREEN } from "./colors";
import Button from "./button";
import MainWrapper from "./main-wrapper";
import { MOBILE } from "./constants";

export const WrapperExtended = styled(MainWrapper)`
  width: 500px;
  margin: 0 auto;
  @media (max-width: ${MOBILE}px) {
    width: 300px;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const FormTitle = styled.h3`
  margin: 7.5px 0;
  font-weight: 600;
  color: ${NICEBLUE};
  font-size: 22px;
`;

export const Paragraph = styled.div`
  color: ${BROWNISHGREY};
  line-height: 1.5;
  margin: 7.5px 0;
`;

export const ApprovalButton = styled(Button)`
  background-color: ${NASTYGREEN};
  padding: 16px;
  color: ${WHITE};
  margin-top: 18px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
