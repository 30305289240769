import styled from "styled-components";
import FlexBox from "./flexbox";
import { NICEBLUE, BROWNISHGREY, NASTYGREEN, WHITE, GREY } from "./colors";
import Input from "./input";
import Button from "./button";
import { MOBILE } from "./constants";

export const ApprovalFormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const TitleContainer = styled(FlexBox)`
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

export const RequestApprovalTitle = styled.h3`
  flex: 0 0 auto;
  margin: ${({ mr = 0, ml = 0 }) => `0 ${mr} 0 ${ml}`};
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ disabled = false, color = NICEBLUE }) =>
    disabled ? GREY : color};
  font-size: 18px;
  @media (max-width: ${MOBILE}px) {
    font-size: 14px;
  }
`;
export const RequestApprovalFormRow = styled(FlexBox)`
  align-items: center;
  margin: 7.5px 0;
  @media (max-width: ${MOBILE}px) {
    flex-flow: column nowrap;
    align-items: center;
  }
`;

export const RequestApprovalFormLabel = styled.label`
  color: ${BROWNISHGREY};
  font-size: 15px;
  width: 100px;
  @media (max-width: ${MOBILE}px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const InputFieldWrapper = styled(Input)`
  width: ${({ width = "300px" }) => width};
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const PhoneFieldWrapper = styled.div`
  position: relative;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const PhonePrefix = styled.span`
  position: absolute;
  top: 5px;
  font-size: 14px;
  padding: 11px;
  color: ${BROWNISHGREY};
`;

export const ActionWrapper = styled(FlexBox)`
  width: 100%;
  @media (max-width: ${MOBILE}px) {
    flex-flow: column nowrap;
  }
`;

export const SendButton = styled(Button)`
  flex: 0 0 auto;
  width: 180px;
  background-color: ${NASTYGREEN};
  padding: 16px;
  color: ${WHITE};
  margin: ${({ margin = "10px 0 0" }) => margin};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${MOBILE}px) {
    margin: 10px 0 0;
    width: 100%;
  }
`;

export const SentButton = styled(Button)`
  flex: 0 0 auto;
  width: 180px;
  background-color: ${NASTYGREEN};
  padding: 16px;
  color: ${WHITE};
  margin: ${({ margin = "10px 0 0" }) => margin};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @media (max-width: ${MOBILE}px) {
    margin: 10px 0 0;
    width: 100%;
    justify-content: center;
  }
`;

export const CheckImage = styled.img`
  height: 16px;
  margin-right: 35px;
  @media (max-width: ${MOBILE}px) {
    margin-right: 10px;
  }
`;

export const Message = styled.div`
  padding: 12.5px 0 20px;
  line-height: 20px;
  font-size: 14px;
  color: ${BROWNISHGREY};
  font-weight: 600;
`;
