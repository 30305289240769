import styled from "styled-components";
import { PALEGREY } from "./colors";
import { IPHONEX } from "./constants";

const RouteContainer = styled.div`
  background-color: ${PALEGREY};
  min-height: calc(100vh - 320px);
  ${IPHONEX} {
    min-height: 100vh;
  }
`;

export default RouteContainer;
