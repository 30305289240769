import "core-js/stable";
import "cross-fetch/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "unfetch/polyfill";

const firebaseConfig = {
  apiKey: "AIzaSyBb-5rGuUin-IICnJemYvc_95nX8lvCH24",
  authDomain: "koverageone.firebaseapp.com",
  databaseURL: "https://koverageone.firebaseio.com",
  projectId: "koverageone",
  storageBucket: "",
  messagingSenderId: "129116078309",
  appId: "1:129116078309:web:b1792344316a7d53"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Needed for Date support in firebase v5.4.2
// const settings = { timestampsInSnapshots: true };

// firebase.firestore().settings(settings);

require("./utility/auth");
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
