import React from "react";
import {
  WrapperExtended,
  NotFoundWrapper,
  Icon,
  BigText,
  Big4,
  Big0,
  Subtitle,
  Button,
  ButtonImage,
  ButtonText
} from "../styles/not-found";
import images from "../utility/images";
import { NASTYGREEN } from "../styles/colors";

export default function NotFound({ history, match }) {
  const backToHome = () => history.push("/");
  return (
    <WrapperExtended
      extraStyles={`
        max-width: 1000px;
        margin: 0 auto;
      `}
    >
      <NotFoundWrapper>
        <BigText>
          <Big4>4</Big4>
          <Big0>
            <Icon src={images.NOT_FOUND} alt='not found' />
          </Big0>
          <Big4>4</Big4>
        </BigText>
        <Subtitle>Oops! No Page Found.</Subtitle>
        <Button onClick={backToHome}>
          <ButtonImage src={images.BACK_GREEN} alt='go back' />
          <ButtonText>back to home</ButtonText>
        </Button>
      </NotFoundWrapper>
    </WrapperExtended>
  );
}
