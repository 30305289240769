import React from "react";
import { withRouter } from "react-router";
import * as firebase from "firebase/app";
import { getUserData } from "../utility/database";
import {
  HeaderContainer,
  Logo,
  ActionContainer,
  LogoutButton
} from "../styles/header";
import IMAGES from "../utility/images";
import Typography from "../styles/typography";
import { BROWNISHGREY, NICEBLUE } from "../styles/colors";

function Header({ history, location: { pathname } }) {
  const [user, setUser] = React.useState(null);
  const onLogout = async () => {
    await global.AUTH.signOut();
    history.replace("/login");
  };

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {
        const data = await getUserData(user.email);
        setUser(data);
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <HeaderContainer>
      <Logo src={IMAGES.LOGO} alt='logo' />{" "}
      {user && !pathname.includes("/approve/") ? (
        <ActionContainer>
          <Typography
            mobileFontSize={14}
            fontSize={18}
            margin='0 18px 0 0'
            color={BROWNISHGREY}
            lineHeight='36px'
          >
            Welcome Back,{" "}
            <Typography mobileFontSize={14} fontSize={18} color={NICEBLUE}>
              {user.firstName}!
            </Typography>{" "}
          </Typography>{" "}
          <LogoutButton
            fontSize={14}
            borderRadius={5}
            padding='10px 20px'
            onClick={onLogout}
          >
            Logout{" "}
          </LogoutButton>{" "}
        </ActionContainer>
      ) : null}{" "}
    </HeaderContainer>
  );
}

export default withRouter(Header);
