import React from "react";
import {
  InfoWrapper,
  ModalTitle,
  Title,
  ModalContent,
  Icon
} from "../styles/info-modal";
import images from "../utility/images";
const InfoModal = ({ unMountModal, title = "", text = "" }) => {
  return (
    <InfoWrapper>
      <ModalTitle>
        <Icon src={images.INFO_WHITE} alt='info' />
        <Title>{title}</Title>
        <Icon
          width='12px'
          height='12px'
          src={images.CLOSE}
          alt='info'
          onClick={unMountModal}
        />
      </ModalTitle>
      {text ? <ModalContent>{text}</ModalContent> : null}
    </InfoWrapper>
  );
};

export default InfoModal;
