import React from "react";
import { withRouter } from "react-router";
import MainWrapper from "../components/main-wrapper";
import IMAGES from "../utility/images";
import { getReferrals } from "../utility/database";
import * as firebase from "firebase/app";
import moment from "moment";

import {
  DashboardWrapper,
  DashboardActions,
  NewReferralButton,
  PastReferralButton,
  DashboardReferralView,
  DashboardReferralTopContent,
  DashboardReferralTopLoader,
  DashboardNoReferralTopContent,
  DashboardOpenReferralsContentBox,
  ReferralWrapper,
  StatusIcon,
  ReferralDetails,
  ReferralContiueButton,
  ReferralName,
  ReferralStatus,
  StatusWrapper,
  ReferralDate,
  Header,
  HeaderDetails,
  HeaderWrapper
} from "../styles/dashboard";
import {
  DASHBOARD_STATUS_LABEL,
  DASHBOARD_STATUS_ROUTING,
  DASHBOARD_STATUS_ICONS,
  INFO_MODAL_CONTENT
} from "../utility/constants";
import DisplayUtil from "../utility/display-util";
import InfoModal from "./info-modal";

const goToForm = (history, url) => () => {
  history.push(url);
};

function Referral({ id, history, ...referral }) {
  const [data, setData] = React.useState(referral);
  const {
    firstName,
    lastName,
    status,
    approvedOn,
    createdOn,
    submittedOn
  } = data;
  React.useEffect(() => {
    const currentUser = global.AUTH.currentUser;
    if (currentUser) {
      const { email } = currentUser;
      return firebase
        .firestore()
        .collection("loanofficers")
        .doc(email)
        .collection("referrals")
        .doc(id)
        .onSnapshot(snapshot => {
          const updated = snapshot.data();
          setData(updated);
        });
    }
  }, [id]);

  const renderErrorModal = unMountModal => {
    const info = INFO_MODAL_CONTENT[status];
    return (
      <InfoModal
        title={info.title}
        text={info.text}
        unMountModal={unMountModal}
      />
    );
  };

  const openInfoModal = () => {
    DisplayUtil.showModal(renderErrorModal, true);
  };

  const getDisplayDate = () => {
    const applicableDate = submittedOn || approvedOn || createdOn;
    return moment(applicableDate.seconds * 1000).format("MM/DD/YYYY");
  };

  const routing = status ? DASHBOARD_STATUS_ROUTING[status] : null;
  return (
    <ReferralWrapper>
      <StatusIcon src={IMAGES[DASHBOARD_STATUS_ICONS[status]]} alt="status" />
      <ReferralDetails>
        <ReferralName>
          {firstName} {lastName}
        </ReferralName>
        <ReferralDate>{getDisplayDate()}</ReferralDate>
        <ReferralStatus>{DASHBOARD_STATUS_LABEL[status]}</ReferralStatus>
      </ReferralDetails>
      {routing && routing.route ? (
        <ReferralContiueButton
          onClick={goToForm(history, `${routing.route.replace("{id}", id)}`)}
        >
          {routing.label}
        </ReferralContiueButton>
      ) : (
        <StatusWrapper>
          <StatusIcon src={IMAGES.INFO} alt="info" onClick={openInfoModal} />
        </StatusWrapper>
      )}
    </ReferralWrapper>
  );
}

const ReferralWithRouter = withRouter(Referral);

const goToRoute = (route, history) => () => {
  history.push(route);
};

function Dashboard({ history }) {
  const [openReferrals, setReferrals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const currentUser = global.AUTH.currentUser;
      if (currentUser) {
        const { email } = currentUser;
        let data = await getReferrals(email);
        setReferrals(data);
        setLoading(false);
      }
    };

    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetchData();
      }
    });
  }, []);
  return (
    <MainWrapper>
      <DashboardWrapper>
        <DashboardActions>
          <NewReferralButton onClick={goToRoute("/approval", history)}>
            Start a New Referral
          </NewReferralButton>
          <PastReferralButton onClick={goToRoute("/referrals", history)}>
            View Past Referrals
          </PastReferralButton>
        </DashboardActions>
        <DashboardReferralView>
          <DashboardReferralTopContent>
            <HeaderWrapper>
              <HeaderDetails>
                <Header>Open Referrals</Header>
                <Header>Date</Header>
                <Header>Status</Header>
              </HeaderDetails>
              <StatusWrapper></StatusWrapper>
            </HeaderWrapper>
            {loading ? (
              <DashboardReferralTopLoader>
                Fetching Referrals, Please wait...
              </DashboardReferralTopLoader>
            ) : null}
            {loading === false && openReferrals.length === 0 ? (
              <DashboardNoReferralTopContent>
                No referrals yet, start a new one by clicking Start a New
                Referral.
              </DashboardNoReferralTopContent>
            ) : null}
          </DashboardReferralTopContent>
          <DashboardOpenReferralsContentBox>
            {openReferrals.map(referral => (
              <ReferralWithRouter key={referral.id} {...referral} />
            ))}
          </DashboardOpenReferralsContentBox>
        </DashboardReferralView>
      </DashboardWrapper>
    </MainWrapper>
  );
}

export default withRouter(Dashboard);
