import React from "react";
import * as firebase from "firebase/app";

import {
  WrapperExtended,
  DetailsContainer,
  Icon,
  DetailRow,
  DetailLabel,
  DetailText,
  TitleContainer,
  TypographyWrapper,
  TypographyTitle,
  TypographySubTitle,
  Loader
} from "../styles/approved-details";
import { capitalize } from "../utility/helper";
import images from "../utility/images";

export default function ApprovedDetails({ match }) {
  const [referral, setReferral] = React.useState(null);
  const getReferral = firebase.functions().httpsCallable("getReferral");

  React.useEffect(() => {
    const fetchName = async () => {
      if (match.params.id) {
        const { data } = await getReferral({ id: match.params.id });
        setReferral(data);
      }
    };
    fetchName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <WrapperExtended
      extraStyles={`
        max-width: 1000px;
        margin: 0 auto;
      `}
    >
      {!referral ? (
        <Loader>Fetching details...</Loader>
      ) : (
        <>
          <TitleContainer>
            <Icon src={images.APPROVED3X} alt="info" />
            <TypographyWrapper>
              <TypographyTitle>Approved</TypographyTitle>
              <TypographySubTitle>
                {capitalize(referral.applicant.firstName)}{" "}
                {capitalize(referral.applicant.lastName)}
              </TypographySubTitle>
            </TypographyWrapper>
          </TitleContainer>
          <DetailsContainer>
            <DetailRow>
              <DetailLabel>Coverage Amount : </DetailLabel>
              <DetailText>$250,000</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Premium : </DetailLabel>
              <DetailText>$1089.45</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Deductable : </DetailLabel>
              <DetailText>$5,000</DetailText>
            </DetailRow>
          </DetailsContainer>
        </>
      )}
    </WrapperExtended>
  );
}
