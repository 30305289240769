import styled from "styled-components";
import { NICEBLUE, WHITE, NASTYGREEN, BROWNISHGREY } from "./colors";
import FlexBox from "./flexbox";
import Input from "./input";
import Button from "./button";
import { MOBILE } from "./constants";

export const AddressWrapper = styled.div`
  margin-bottom: 10px;
`;

export const AddressTitle = styled.h4`
  margin: 0;
  color: ${NICEBLUE};
  font-weight: 400;
  margin-bottom: 10px;
`;

export const AddressContentBox = styled(FlexBox)`
  flex-wrap: wrap;
  align-items: center;
  width: 550px;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StreetWrapper = styled.div`
  flex: 0 0 auto;
  width: 550px;
  margin-bottom: 10px;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
  }
`;

export const StateWrapper = styled.div`
  width: 180px;
  & > div {
    backgroundcolor: PALE20;
  }
  & input {
    font-weight: 600;
  }
  & span {
    font-weight: 600;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
    & > div {
      background-color: ${WHITE};
    }
    & input {
      font-weight: 600;
    }
    & span {
      font-weight: 600;
    }
  }
`;

export const InputWrapper = styled(Input)`
  flex: 0 0 auto;
  width: ${({ width = "100%" }) => width};
  margin: ${({ margin = "0 10px 0 0" }) => margin};
  color: ${BROWNISHGREY};
  font-weight: 600;
  background-color: ${WHITE};
  &::placeholder {
    color: ${BROWNISHGREY};
    font-weight: 600;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
  }
`;

export const DatePickerWrapper = styled.div`
  flex: 0 0 auto;
  width: ${({ width = "100%" }) => width};
  & > div {
    width: 100%;
  }
  & input {
    color: ${BROWNISHGREY};
    font-weight: 600;
    background-color: ${WHITE};
    &::placeholder {
      color: ${BROWNISHGREY};
      font-weight: 600;
    }
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const ApplicantTitle = styled.h4`
  color: ${NICEBLUE};
  font-weight: 400;
  margin: 10px 0;
`;

export const UserFormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const UserFormTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  color: ${NICEBLUE};
  font-size: 22px;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    text-align: center;
  }
`;

export const UserFormRow = styled(FlexBox)`
  align-items: center;
  margin: 7.5px 0;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: ${({ marginMedium = false }) =>
      marginMedium ? "7.5px 0 16px" : "7.5px 0"};
  }
`;

export const UserFormLabel = styled.label`
  color: ${BROWNISHGREY};
  font-size: 15px;
  width: 100px;
  margin-right: 50px;
  @media (max-width: ${MOBILE}px) {
    margin: 0 0 10px 0;
  }
`;

export const Error = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  white-space: nowrap;
  color: red;
`;
export const SubmitButton = styled(Button)`
  background-color: ${NASTYGREEN};
  padding: 16px;
  width: 250px;
  color: ${WHITE};
  text-transform: uppercase;
  margin-top: 18px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;
