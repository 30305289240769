import styled from "styled-components";
import FlexBox from "./flexbox";
import { NICEBLUE, BROWNISHGREY } from "./colors";
import { MOBILE } from "./constants";

export const PastReferralWrapper = styled(FlexBox)`
  flex-flow: column nowrap
  column-gap: 20px;
`;

export const TitleContainer = styled(FlexBox)`
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: ${MOBILE}px) {
    flex-flow: column nowrap;
  }
`;

export const TitleText = styled.h3`
  flex: 1;
  margin: 0;
  font-size: 34px;
  color: ${NICEBLUE};
  @media (max-width: ${MOBILE}px) {
    margin-bottom: 15px;
  }
`;

export const FilterContent = styled(FlexBox)`
  flex: 0 0 auto;
  flex-wrap: nowrap;
`;

export const FilterItem = styled(FlexBox)`
  flex-flow: column nowrap;
  width: 80px;
  margin: 0 0 10px 20px;
  @media (max-width: ${MOBILE}px) {
    margin: 0 20px 10px 0;
  }
`;

export const FilterLabel = styled.label`
  font-size: 14px;
  color: ${BROWNISHGREY};
  margin-bottom: 5px;
`;

export const DataTable = styled(FlexBox)`
  width: 100%;
  flex-flow: column nowrap;
  margin-top: 25px;
`;

export const DataTableHeading = styled(FlexBox)`
  flex-wrap: nowrap;
  justify-content: center;
`;

export const DataLabel = styled.label`
  flex: 1;
  color: ${NICEBLUE};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  font-weight: 600;
  text-align: center;
  @media (max-width: ${MOBILE}px) {
    text-align: left;
    margin-right: 10px;
    margin-left: 5px;
    &::last-child {
      margin-right: 0;
    }
  }
`;

export const DataRow = styled(FlexBox)`
  flex-wrap: nowrap;
  justify-items: center;
  justify-self: stretch;
  padding: 12px;
  font-size: 14px;
  color: ${BROWNISHGREY};
  &:nth-of-type(odd) {
    background: #f5f5f5;
  }
  @media (max-width: ${MOBILE}px) {
    padding: 12px 5px;
  }
`;

export const DataContent = styled.div`
  @media (max-width: ${MOBILE}px) {
    text-align: left;
  }
`;

export const DataItem = styled.div`
  flex: 1;
  text-transform: ${({ textTransform = "none" }) => textTransform};
  text-align: center;
  @media (max-width: ${MOBILE}px) {
    text-align: left;
    margin-right: 10px;
    &::last-child {
      margin-right: 0;
    }
  }
`;
