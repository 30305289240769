import styled from "styled-components";
import { PRIMARY_FONT } from "./fonts";
import { NASTYGREEN, WHITE } from "./colors";

const StyledButton = styled.button`
  border: ${({ border = `1px solid ${NASTYGREEN}` }) => border};
  shadow: none;
  border-radius: ${({ radius = 5 }) => `${radius}px`};
  padding: ${({ padding = "10px" }) => padding};
  margin: ${({ margin = "0" }) => margin};
  background-color: ${({ bgColor = WHITE }) => bgColor};
  font-family: ${({ fontFamily = PRIMARY_FONT }) => fontFamily};
  font-size: ${({ fontSize = 16 }) => `${fontSize}px`};
  font-weight: ${({ fontWeight = "400" }) => fontWeight};
  color: ${({ color = NASTYGREEN }) => color};
  text-align: ${({ textAlign = "center" }) => textAlign};
  line-height: ${({ lineHeight = 1 }) => lineHeight};
  cursor: pointer;
  outline: none;
`;

export default StyledButton;
