import React from "react";
import { withRouter } from "react-router";
import Input from "../styles/input";
import {
  LoginWrapper,
  LoginForm,
  LoginFormTitle,
  LoginError,
  LoginButton,
  ForgotPasswordButton
} from "../styles/login";

function Login({ history }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const changeInput = fieldName => event => {
    if (fieldName === "password") {
      setPassword(event.target.value);
    } else if (fieldName === "username") {
      setUsername(event.target.value);
    }
  };

  const onLogin = async event => {
    event.preventDefault();
    const success = await global.AUTH.signIn(username, password);
    setError(!success);
    if (success) {
      history.replace("/dashboard");
    }
  };

  return (
    <LoginWrapper>
      <LoginForm onSubmit={onLogin}>
        <LoginFormTitle>Please Login</LoginFormTitle>
        <Input
          margin="7px 0"
          placeholder="Username"
          value={username}
          onChange={changeInput("username")}
        />
        <Input
          margin="7px 0 14px"
          type="password"
          placeholder="Password"
          value={password}
          onChange={changeInput("password")}
        />
        {error ? <LoginError>Invalid Username/Password</LoginError> : null}
        <LoginButton disabled={!username || !password}>Login</LoginButton>
        <ForgotPasswordButton type="submit">
          Forgot Password?
        </ForgotPasswordButton>
      </LoginForm>
    </LoginWrapper>
  );
}

export default withRouter(Login);
