import styled from "styled-components";
import { DARK20, BROWNISHGREY, PALE20 } from "./colors";

const Input = styled.input`
  outline: none;
  border: 1px solid ${DARK20};
  background: ${PALE20};
  font-size: 14px;
  padding: 15px 20px;
  padding-left: ${({ prefixed = false }) => (prefixed ? "26px" : "15px")};
  margin: ${({ margin = 0 }) => margin}
  border-radius: 5px;
  color: ${BROWNISHGREY};
  width: ${({ width = "100%" }) => width};
`;

export default Input;
