import styled from "styled-components";
import { NICEBLUE, WHITE, NASTYGREEN, BROWNISHGREY } from "./colors";
import FlexBox from "./flexbox";
import Input from "./input";
import Button from "./button";
import { MOBILE } from "./constants";

export const AddressWrapper = styled.div``;

export const AddressTitle = styled.h4`
  margin: 10px 0 5px;
  color: ${NICEBLUE};
  font-weight: 400;
`;

export const AddressContentBox = styled(FlexBox)`
  flex-wrap: wrap;
  align-items: center;
  width: 500px;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StreetWrapper = styled.div`
  flex: 0 0 auto;
  width: 500px;
  margin: 7.5px 0;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
  }
`;

export const StateWrapper = styled.div`
  width: 155px;
  & input {
    font-weight: 600;
  }
  & span {
    font-weight: 600;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
    & > div {
      background-color: ${WHITE};
    }
    & input {
      font-weight: 600;
    }
    & span {
      font-weight: 600;
    }
  }
`;

export const InputWrapper = styled(Input)`
  flex: 0 0 auto;
  width: ${({ width = "100%" }) => width};
  margin: ${({ margin = "0 10px 0 0" }) => margin};
  color: ${BROWNISHGREY};
  font-weight: 600;
  &::placeholder {
    color: ${BROWNISHGREY};
    font-weight: 600;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    margin: 8px 0;
    background-color: ${WHITE};
  }
`;

export const DatePickerWrapper = styled.div`
  flex: 0 0 auto;
  width: ${({ width = "100%" }) => width};
  & > div {
    width: 100%;
  }
  & input {
    color: ${BROWNISHGREY};
    font-weight: 600;
    &::placeholder {
      color: ${BROWNISHGREY};
      font-weight: 600;
    }
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    & input {
      background-color: ${WHITE};
    }
  }
`;

export const ApplicantTitle = styled.h4`
  color: ${NICEBLUE};
  font-weight: 400;
  margin: 10px 0 0;
`;

export const UserFormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const UserFormTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  color: ${NICEBLUE};
  font-size: 22px;
`;

export const UserFormRow = styled(FlexBox)`
  align-items: center;
  margin: 7.5px 0;
  @media (max-width: ${MOBILE}px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: ${({ marginMedium = false }) =>
      marginMedium ? "7.5px 0 16px" : "7.5px 0"};
  }
`;

export const UserFormLabel = styled.label`
  color: ${BROWNISHGREY};
  font-size: 15px;
  width: 100px;
  margin-right: ${({ mr = "50px" }) => mr};
  @media (max-width: ${MOBILE}px) {
    display: none;
  }
`;

export const Error = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  white-space: nowrap;
  color: red;
`;
export const SubmitButton = styled(Button)`
  background-color: ${NASTYGREEN};
  padding: 16px;
  width: 250px;
  color: ${WHITE};
  text-transform: uppercase;
  margin-top: 18px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${MOBILE}px) {
    width: 100%;
  }
`;

export const IntroText = styled.div`
  font-size: 16px;
  line-height: 1.4;
  color: ${BROWNISHGREY};
  padding-top: 10px;
  padding-bottom: ${({ pb = "0" }) => pb};
`;

export const IntroTextGreen = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: ${NASTYGREEN};
`;

export const UserFormLabelMobile = styled.label`
  display: none;
  @media (max-width: ${MOBILE}px) {
    display: inherit;
    color: ${BROWNISHGREY};
    font-size: 15px;
    width: 100px;
    margin: 0 0 10px 0;
  }
`;

export const ButtonImage = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "15px" }) => height};
  width: ${({ width = "15px" }) => width};
  object-fit: contain;
  margin-right: 8px;
  padding: 1px;
`;

export const ValidateButton = styled(Button)`
  background-color: ${NICEBLUE};
  padding: 5px 10px;
  width: 150px;
  color: ${WHITE};
  text-transform: uppercase;
  margin: 7px 0;
  font-size: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid ${NICEBLUE};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${MOBILE}px) {
    width: 150px;
    font-size: 12px;
  }
`;

export const ValidateAddressWrapper = styled.div`
  flex: 0 0 auto;
  @media (max-width: ${MOBILE}px) {
    margin: 0 auto;
  }
`;
