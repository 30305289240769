import React from "react";
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import * as firebase from "firebase/app";
import "react-datepicker/dist/react-datepicker.css";
import MainWrapper from "../components/main-wrapper";
import { US_STATES, STATUS_TYPES } from "../utility/constants";
import {
  AddressWrapper,
  ApplicantTitle,
  AddressTitle,
  AddressContentBox,
  StreetWrapper,
  StateWrapper,
  InputWrapper,
  DatePickerWrapper,
  SubmitButton,
  UserFormWrapper,
  UserFormRow,
  UserFormLabel,
  IntroText,
  IntroTextGreen,
  UserFormLabelMobile,
  Error,
  ButtonImage,
  ValidateButton,
  ValidateAddressWrapper
} from "../styles/user-detail-form";
import { BROWNISHGREY, PALE20, DARK20, LIGHTGREY } from "../styles/colors";
import moment from "moment";
import images from "../utility/images";

const MIN_DOB_DATE = new Date(1939, 0, 1);
const MAX_DOB_DATE = new Date(2002, 0, 1);
const getStateObject = state =>
  US_STATES.filter(({ value }) => value === state);

function Address({
  street,
  city,
  state,
  zip,
  changeAddress,
  changeState,
  title,
  saving,
  setAddressErrors
}) {
  const changeInput = fieldName => event => {
    if (event.target.value) {
      changeAddress(fieldName)(event.target.value);
    }
  };

  const [selectedState, setSelectedState] = React.useState(
    getStateObject(state)
  );

  const onChangeState = values => {
    const value = values[0].value;
    if (value) {
      changeAddress("state")(value);
    }
  };

  const validate = async e => {
    e.preventDefault();
    // const result = await getValidatedAddress({ street, city, state, zip });
    // if (!result) {
    //   setAddressErrors(`${title} is not valid!`);
    // }
  };

  React.useEffect(() => {
    if (state) {
      const selectedState = getStateObject(state);
      setSelectedState(selectedState);
    }
  }, [state]);

  return (
    <AddressWrapper>
      <AddressTitle>{title}</AddressTitle>
      <AddressContentBox>
        <StreetWrapper>
          <InputWrapper
            placeholder="Street"
            value={street}
            onChange={changeInput("street")}
            disabled={saving}
          />
        </StreetWrapper>
        <InputWrapper
          width="190px"
          placeholder="City"
          margin="7.5px 10px 7.5px 0"
          value={city}
          onChange={changeInput("city")}
          disabled={saving}
        />
        <InputWrapper
          width="135px"
          placeholder="Zipcode"
          margin="7.5px 10px 7.5px 0"
          value={zip}
          onChange={changeInput("zip")}
          disabled={saving}
        />
        <StateWrapper>
          <Select
            options={US_STATES}
            style={{
              width: "100%",
              borderRadius: 5,
              border: `1px solid ${DARK20}`,
              backgroundColor: PALE20,
              fontSize: 14,
              padding: "14px 24px",
              color: BROWNISHGREY,
              margin: "7.5px 0",
              fontWeight: "600"
            }}
            placeholder="State"
            values={selectedState}
            onChange={onChangeState}
            disabled={saving}
          />
        </StateWrapper>
        <ValidateAddressWrapper>
          <ValidateButton onClick={validate}>
            <ButtonImage src={images.APPROVED_CIRCLE} alt="validate" />
            Validate Address
          </ValidateButton>
        </ValidateAddressWrapper>
      </AddressContentBox>
    </AddressWrapper>
  );
}

function Applicant({
  firstName,
  lastName,
  dateOfBirth,
  title,
  changeInput,
  changeValue,
  saving
}) {
  return (
    <React.Fragment>
      <UserFormRow>
        <ApplicantTitle>{title}</ApplicantTitle>
      </UserFormRow>
      <UserFormRow>
        <InputWrapper
          width="500px"
          placeholder="First Name"
          value={firstName}
          onChange={changeInput("firstName")}
          disabled={saving}
        />
      </UserFormRow>
      <UserFormRow>
        <InputWrapper
          width="500px"
          placeholder="Last Name"
          value={lastName}
          onChange={changeInput("lastName")}
          disabled={saving}
        />
      </UserFormRow>
      <UserFormRow>
        <DatePickerWrapper width="500px">
          <DatePicker
            placeholderText="DOB"
            selected={dateOfBirth}
            onChange={changeValue("dateOfBirth")}
            minDate={MIN_DOB_DATE}
            maxDate={MAX_DOB_DATE}
            showYearDropdown
            disabled={saving}
          />
        </DatePickerWrapper>
      </UserFormRow>
    </React.Fragment>
  );
}

function UserDetailsForm({ match, history }) {
  const [user, setUser] = React.useState({});
  const [loanOfficer, setLoanOfficer] = React.useState({});
  const [referralId, setReferralId] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const getReferralDetails = firebase
    .functions()
    .httpsCallable("getReferralDetails");
  const getValidatedAddress = firebase
    .functions()
    .httpsCallable("validateAddress");
  const approveReferral = firebase.functions().httpsCallable("approveReferral");
  const saveReferralDetails = firebase
    .functions()
    .httpsCallable("saveReferralDetails");

  const changeInput = fieldName => event => {
    const value = event.target.value;
    setUser({ ...user, [fieldName]: value });
  };

  const changeNestedInput = parent => fieldName => event => {
    const value = event.target.value;
    setUser({ ...user, [parent]: { ...user[parent], [fieldName]: value } });
  };

  const changeNestedValue = parent => fieldName => value => {
    setUser({ ...user, [parent]: { ...user[parent], [fieldName]: value } });
  };

  const changeAddress = type => fieldName => value => {
    setUser({ ...user, [type]: { ...user[type], [fieldName]: value } });
  };

  const massageData = data => {
    const errors = [];
    const {
      borrower,
      coBorrower = { firstName: "", lastName: "", dateOfBirth: null },
      previousAddress,
      address,
      loanAmount
    } = data;
    let currentAddress = data.currentAddress || previousAddress;
    let status = STATUS_TYPES.SUBMITTED_TO_UNDERWRITING;
    let submitted = true;
    if (!borrower.firstName) errors.push("Applicant First Name is missing");
    if (!borrower.lastName) errors.push("Applicant Last Name is missing");
    if (!borrower.dateOfBirth) {
      errors.push("Applicant DOB is missing");
    }
    if (coBorrower.lastName && !coBorrower.firstName)
      errors.push("Co Applicant First Name is missing");
    if (coBorrower.firstName && !coBorrower.lastName)
      errors.push("Co Applicant Last Name is missing");
    if (
      (coBorrower.firstName || coBorrower.lastName) &&
      !coBorrower.dateOfBirth
    ) {
      errors.push("Co Applicant DOB is missing");
    }
    if (!borrower.occupation) errors.push("Applicant Profession is missing");
    if (!loanAmount) {
      status = STATUS_TYPES.DATA_NEEDED;
      submitted = false;
    }
    if (!currentAddress || !currentAddress.street || !currentAddress.zip)
      errors.push("Current Address is incomplete");
    if (!address || !address.street || !address.zip)
      errors.push("Property Address is incomplete");
    if (
      currentAddress &&
      currentAddress.zip &&
      currentAddress.zip.length !== 5
    ) {
      errors.push("Current Address zipcode is invalid");
    }
    if (address && address.zip && address.zip.length !== 5) {
      errors.push("Property Address zipcode is invalid");
    }
    if (errors.length) {
      return { success: false, errors };
    }
    const submission = {
      borrower: {
        ...borrower,
        dateOfBirth: borrower.dateOfBirth
          ? moment(borrower.dateOfBirth).format("MM/DD/YYYY")
          : ""
      },
      address: {
        ...address,
        state: address.state || "CT"
      },
      currentAddress: {
        ...currentAddress,
        state: currentAddress.state || "CT"
      },
      loanAmount,
      submittedOn: firebase.firestore.Timestamp.fromDate(new Date()),
      submitted,
      status
    };
    if (coBorrower.firstName) {
      submission.coBorrower = {
        ...coBorrower,
        dateOfBirth: coBorrower.dateOfBirth
          ? moment(coBorrower.dateOfBirth).format("MM/DD/YYYY")
          : ""
      };
    }
    if (submission.borrower.dateOfBirth) {
      delete submission.borrower.dob;
    }
    if (submission.coBorrower && submission.coBorrower.dob) {
      delete submission.coBorrower.dob;
    }
    return { success: true, submission };
  };

  const onSubmit = async event => {
    event.preventDefault();
    setSaving(true);
    if (loanOfficer) {
      const { id: email } = loanOfficer;
      const { success, errors = [], submission: referral = null } = massageData(
        user
      );
      if (success) {
        setErrors([]);
        referral.id = referralId;
        const referralParams = {
          email,
          referral
        };
        await saveReferralDetails(referralParams);
        if (referralId) {
          await approveReferral({
            id: referralId,
            loanOfficerId: loanOfficer.id,
            approvalType: "email",
            status: referral.status
          });
        }
        history.replace("/thankyou");
      } else {
        setErrors(errors);
      }
    }
    setSaving(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setSaving(true);
      let referralId = match.params.id;
      if (referralId) {
        const { data } = await getReferralDetails({ id: referralId });
        if (data.id) {
          referralId = data.id;
        }
        debugger;
        setUser(data.applicant);
        setLoanOfficer(data.loanOfficer);
        setReferralId(referralId);
      }
      setSaving(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const {
    borrower = {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      occupation: ""
    },
    coBorrower = {
      firstName: "",
      lastName: "",
      dateOfBirth: null
    },
    currentAddress = { street: "", state: "CT", zip: "", city: "" },
    address = { street: "", state: "CT", zip: "", city: "" },
    loanAmount
  } = user;
  let previousAddress = user.previousAddress
    ? { ...user.previousAddress }
    : { ...currentAddress };

  return (
    <MainWrapper
      extraStyles={{
        padding: "50px 72px",
        width: "1000px",
        margin: "0 auto;"
      }}
      mobileStyles={{
        backgroundColor: LIGHTGREY,
        top: 0,
        maxWidth: "100%",
        borderRadius: 0,
        boxShadow: "none"
      }}
    >
      <IntroText>
        <IntroTextGreen>
          {loanOfficer.firstName
            ? `${loanOfficer.firstName} ${loanOfficer.lastName}`
            : "Our Agent"}
        </IntroTextGreen>{" "}
        has sent us your information because you are interested in getting the
        best rates on homeowner’s insurance. Please take just a few minutes to
        fill out this form and we’ll compare quotes from all the best carriers
        and send you a proposal for the best price.
      </IntroText>
      <IntroText pb="10px">
        By filling this for out, you’ll not only get the best prices, but this
        will help speed up your application process.
      </IntroText>
      <UserFormWrapper onSubmit={onSubmit}>
        <Applicant
          {...borrower}
          changeInput={changeNestedInput("borrower")}
          changeValue={changeNestedValue("borrower")}
          title="Applicant"
          saving={saving}
        />
        <UserFormRow>
          <InputWrapper
            width="500px"
            placeholder="Profession"
            value={borrower.occupation}
            onChange={changeNestedInput("borrower")("occupation")}
            disabled={saving}
          />
        </UserFormRow>
        <Applicant
          {...coBorrower}
          changeInput={changeNestedInput("coBorrower")}
          changeValue={changeNestedValue("coBorrower")}
          title="Co-Applicant"
          saving={saving}
        />
        <Address
          {...address}
          changeAddress={changeAddress("address")}
          title="Property Address"
          saving={saving}
          setAddressErrors={err => setErrors([...errors, err])}
        />
        <Address
          {...previousAddress}
          changeAddress={changeAddress("currentAddress")}
          title="Current Address"
          saving={saving}
          setErrors={err => setErrors([...errors, err])}
        />
        <UserFormRow marginMedium>
          <UserFormLabel mr="10px;">
            Requested <br />
            Loan Amount
          </UserFormLabel>
          <UserFormLabelMobile mr="10px;">Loan Amount</UserFormLabelMobile>
          <InputWrapper
            width="385px"
            margin="0 10px 0 0"
            placeholder="Loan Amount"
            value={loanAmount}
            onChange={changeInput("loanAmount")}
            disabled={saving}
          />
        </UserFormRow>
        {errors && errors.length
          ? errors.map(error => <Error key={error}>{error}</Error>)
          : null}
        <SubmitButton disabled={saving}>Submit</SubmitButton>
      </UserFormWrapper>
    </MainWrapper>
  );
}

export default withRouter(UserDetailsForm);
