import React, { useRef, useState } from "react";
import { ModalOverlay, ModalBodyContainer } from "../styles/generic-modal";

const GenericModal = ({
  unMountOnOutsideClick = false,
  extraStyle = "",
  unMountModal,
  renderModalBody
}) => {
  const node = useRef();
  const [transitionState, setTransitionState] = useState("in");
  const [opacity, setOpacity] = useState("1");

  const closeModal = () => {
    setTransitionState("out");
    // unMountModal()
    setTimeout(unMountModal, 500);
  };
  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }
    if (unMountOnOutsideClick) {
      closeModal();
    }
  };

  const modalBody = renderModalBody(closeModal, setOpacity);

  return (
    <ModalOverlay className={transitionState} onClick={handleClick}>
      <ModalBodyContainer
        extraStyle={extraStyle}
        className={transitionState}
        ref={node}
        opacity={opacity}
      >
        {modalBody}
      </ModalBodyContainer>
    </ModalOverlay>
  );
};

export default GenericModal;
