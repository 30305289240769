import LOGO from "../images/logo.png";
import HERO from "../images/hero.png";
import APPROVED from "../images/approved.png";
import EMAIL from "../images/email.png";
import BACK from "../images/back.png";
import CHECKED from "../images/checked.png";
import EDIT from "../images/edit.png";
import OK from "../images/ok.png";
import INFO from "../images/info.png";
import WAITING from "../images/waiting.png";
import CLOSE from "../images/close.png";
import INFO_WHITE from "../images/info-white.png";
import APPROVED3X from "../images/approved3x.png";
import NOT_FOUND from "../images/404.png";
import BACK_GREEN from "../images/back-green.png";
import WAITING3X from "../images/waiting3x.png";
import CALENDAR from "../images/calendar.png";
import APPROVED_CIRCLE from "../images/approved-circle.png";

export default {
  LOGO,
  HERO,
  APPROVED,
  EMAIL,
  BACK,
  CHECKED,
  EDIT,
  OK,
  INFO,
  WAITING,
  CLOSE,
  INFO_WHITE,
  APPROVED3X,
  NOT_FOUND,
  BACK_GREEN,
  WAITING3X,
  CALENDAR,
  APPROVED_CIRCLE
};
