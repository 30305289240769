import { LOGIN_KEY } from "../utility/constants";
import * as firebase from "firebase/app";

class Authentication {
  constructor() {
    this._isAuthenticated = window.localStorage.getItem(LOGIN_KEY) === "true";
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        window.localStorage.setItem(LOGIN_KEY, "true");
        this._isAuthenticated = true;
      } else {
        window.localStorage.removeItem(LOGIN_KEY);
        this._isAuthenticated = false;
      }
    });
  }

  get currentUser() {
    if (this._isAuthenticated) {
      return firebase.auth().currentUser;
    }
    return null;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  async signIn(email, password) {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      window.localStorage.setItem(LOGIN_KEY, "true");
      this._isAuthenticated = true;
      return true;
    } catch (_) {
      console.log(_);
    }
    return false;
  }

  async signOut() {
    await firebase.auth().signOut();
    window.localStorage.removeItem(LOGIN_KEY);
    this._isAuthenticated = false;
  }
}

global.AUTH = new Authentication();
