import React from "react";
import { withRouter } from "react-router";
import IMAGES from "../utility/images";
import { WELCOME_ROUTES, NO_BACK_ROUTES } from "../utility/constants";
import {
  HeroContainer,
  HeroImage,
  HeroBackButton,
  HeroBackImage,
  WelcomeText
} from "../styles/hero";
import { WHITE } from "../styles/colors";

function Hero({ location: { pathname }, history }) {
  const onBack = () => history.goBack();
  return (
    <HeroContainer
      hideInMobile={
        pathname.includes("/details/") || pathname.includes("/details-form/")
      }
    >
      {WELCOME_ROUTES.includes(pathname) ||
      pathname.includes("/details-form/") ? (
        <WelcomeText
          justifyContent='center'
          alignItems='center'
          fontSize={28}
          color={WHITE}
          fontWeight={600}
          textAlign='center'
        >
          Welcome to the KoverageOne Portal!
        </WelcomeText>
      ) : null}
      {NO_BACK_ROUTES.includes(pathname) ||
      pathname.includes("/approve/") ||
      pathname.includes("/details-form/") ? null : (
        <HeroBackButton onClick={onBack}>
          <HeroBackImage src={IMAGES.BACK} alt='hero' /> Back
        </HeroBackButton>
      )}
      <HeroImage src={IMAGES.HERO} alt='hero' />
    </HeroContainer>
  );
}

export default withRouter(Hero);
