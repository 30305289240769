import * as firebase from "firebase/app";

export const getUserData = async email => {
  const loanOfficers = firebase.firestore().collection("loanofficers");
  const reference = loanOfficers.doc(email);
  const record = await reference.get();
  if (record.exists) {
    const data = record.data();
    return { ...data, email };
  }
  return null;
};

const massageReferral = referral => {
  if (referral.submissionDate) {
    referral.submissionDate = referral.submissionDate.toDate();
  }
  return referral;
};

export const getReferrals = async email => {
  const loanOfficers = firebase.firestore().collection("loanofficers");
  const reference = loanOfficers.doc(email);
  const referrals = [];
  const snapshot = await reference
    .collection("referrals")
    .orderBy("status", "asc")
    .get();
  snapshot.forEach(function(doc) {
    referrals.push(massageReferral({ id: doc.id, ...doc.data() }));
  });
  return referrals;
};

export const getReferral = async (email, id) => {
  console.log("getReferral", email, id);
  const loanOfficers = firebase.firestore().collection("loanofficers");
  const reference = loanOfficers.doc(email);
  const snapshot = await reference
    .collection("referrals")
    .doc(id)
    .get();
  if (snapshot.exists) {
    return { ...snapshot.data(), id };
  }
  return null;
};

export const saveReferral = async (email, referral) => {
  console.log("Saving", referral);
  const db = firebase.firestore();
  const loanOfficers = db.collection("loanofficers");
  const reference = loanOfficers.doc(email);
  if (referral.id) {
    await reference
      .collection("referrals")
      .doc(referral.id)
      .update(referral);
  } else {
    const newReferral = {
      ...referral,
      createdOn: firebase.firestore.Timestamp.fromDate(new Date())
    };
    const { id } = await reference.collection("referrals").add(newReferral);
    await db
      .collection("lorid")
      .doc(id)
      .set({ email });
    return { id, ...referral };
  }
  return referral;
};
