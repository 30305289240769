import React from "react";
import ReactDOM from "react-dom";
import GenericModal from "../components/generic-modal";

class DisplayUtil {
  static getDialogContainer = (containerClassName = "") => {
    let container = document.getElementById("modal-root");
    if (!container) {
      container = document.createElement("div");
      container.id = "modal-root";
      container.className = containerClassName;
      document.getElementsByTagName("body")[0].appendChild(container);
    }
    return container;
  };

  static removeDialogContainer = container => {
    ReactDOM.unmountComponentAtNode(container);
  };

  static showModal(
    renderModal,
    unMountOnOutsideClick = false,
    extraStyle = "",
    removeDialogContainerCallback = null
  ) {
    const container = DisplayUtil.getDialogContainer();
    const unMountModal = () => {
      if (removeDialogContainerCallback) {
        removeDialogContainerCallback();
      }
      DisplayUtil.removeDialogContainer(container);
    };
    ReactDOM.render(
      <GenericModal
        renderModalBody={renderModal}
        unMountModal={unMountModal}
        extraStyle={extraStyle}
        unMountOnOutsideClick={unMountOnOutsideClick}
      />,
      container
    );
  }
}

export default DisplayUtil;
