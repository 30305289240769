import styled from "styled-components";
import StyledButton from "./button";
import FlexBox from "./flexbox";
import { WHITE, NASTYGREEN } from "./colors";
import { TABLET, MOBILE } from "./constants";
import Typography from "./typography";

export const HeaderContainer = styled(FlexBox)`
  padding: 30px;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${TABLET}px) {
    flex-direction: column;
    padding: 30px 50px 15px;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 60px;
  @media (max-width: ${MOBILE}px) {
    height: 70px;
    margin-bottom: 20px;
  }
`;

export const ActionContainer = styled(FlexBox)`
  align-items: center;
  @media (max-width: ${MOBILE}px) {
    align-items: center;
  }
`;

export const TypographyWrapper = styled(Typography)`
  @media (max-width: ${MOBILE}px) {
    flex-flow: column nowrap;
    align-item: flex-start;
  }
`;

export const LogoutButton = styled(StyledButton)`
  text-transform: uppercase;
  &:active {
    color: ${WHITE};
    background-color: ${NASTYGREEN};
  }
  @media (max-width: ${MOBILE}px) {
    width: 100px;
    align-self: flex-end;
    padding: 10px 5px;
    font-size: 12px;
  }
`;
