import styled from "styled-components";
import { NICEBLUE, NASTYGREEN, PINK, BROWNISHGREY } from "./colors";
import MainWrapper from "./main-wrapper";
import { MOBILE } from "./constants";
import FlexBox from "./flexbox";

export const WrapperExtended = styled(MainWrapper)`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: ${MOBILE}px) {
    width: 300px;
  }
`;

export const TitleContainer = styled(FlexBox)`
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: ${MOBILE}px) {
    justify-content: center;
  }
`;

export const TypographyWrapper = styled.div`
  flex: 1;
`;

export const TypographyTitle = styled.div`
  font-size: 23px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${NICEBLUE};
  @media (max-width: ${MOBILE}px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const TypographySubTitle = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${NASTYGREEN};
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Icon = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "50px" }) => height};
  width: ${({ width = "50px" }) => width};
  margin-right: 15px;
  object-fit: contain;
  @media (max-width: ${MOBILE}px) {
    height: 35px;
    width: 35px;
    margin: 0 15px;
  }
`;

export const DetailsContainer = styled.div``;

export const DetailRow = styled(FlexBox)`
  background-color: ${PINK};
  border-radius: 5px;
  padding: 20px 30px;
  margin: 10px 0;
`;

export const DetailLabel = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: ${BROWNISHGREY};
  margin-right: 5px;
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
  }
`;

export const DetailText = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: ${NICEBLUE};
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
  }
`;

export const Loader = styled.div`
  width: 900px;
  text-align: center;
  padding: 20px 0;
  margin 0 auto;
  color: ${BROWNISHGREY}
`;
