import styled from "styled-components";
import { NASTYGREEN, BROWNISHGREY } from "./colors";
import MainWrapper from "./main-wrapper";
import { MOBILE } from "./constants";
import FlexBox from "./flexbox";

export const WrapperExtended = styled(MainWrapper)`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: ${MOBILE}px) {
    width: 300px;
  }
`;

export const Icon = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "40px" }) => height};
  width: ${({ width = "40px" }) => width};
  object-fit: contain;
  margin: 15px;
`;

export const NotFoundWrapper = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 500px;
`;

export const BigText = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

export const Big4 = styled.div`
  color: ${NASTYGREEN};
  font-size: 150px;
  margin: 0 15px;
`;

export const Big0 = styled.div`
  border-radius: 50%;
  border: 10px solid ${NASTYGREEN};
`;

export const Subtitle = styled.div`
  color: ${BROWNISHGREY};
  font-size: 30px;
  margin: 10px;
`;

export const Button = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  width: 205px;
  border: 1px solid ${NASTYGREEN};
  border-radius: 2px;
  padding: 10px 5px;
  margin-top: 35px;
  cursor: pointer;
`;

export const ButtonImage = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "20px" }) => height};
  width: ${({ width = "30px" }) => width};
  padding: 5px;
  object-fit: contain;
  margin-right: 10px;
`;

export const ButtonText = styled.div`
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${BROWNISHGREY};
  letter-spacing: 1.8;
`;
