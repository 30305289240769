import styled from "styled-components";
import StyledButton from "./button";
import Typography from "./typography";
import FlexBox from "./flexbox";

export const HeroContainer = styled(FlexBox)`
  position: relative;
  @media (max-width: 600px) {
    display: ${({ hideInMobile = false }) => (hideInMobile ? "none" : "flex")};
  }
`;

export const HeroBackImage = styled.img`
  height: 12px;
  margin-right: 10px;
`;

export const HeroBackButton = styled(StyledButton)`
  position: absolute;
  top: 50px;
  left: 50px;
  border: 1px solid white;
  background: none;
  border-radius: 3px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 20px;
  cursor: pointer;
`;

export const HeroImage = styled.img`
  width: 100vw;
  height: 200px;
  object-fit: cover;
  @media (max-width: 600px) {
    min-height: 40px;
  }
`;

export const WelcomeText = styled(Typography)`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
`;
