export const PALEGREY = "#edf2f8";
export const PALEGREYTWO = "#f1f1f3";
export const DARK = "#1d2226";
export const DARK20 = "rgba(29, 34, 38, 0.2)";
export const PALE = "#e6e4e4";
export const PALE20 = "rgba(230, 228, 228, 0.2)";
export const BROWNISHGREY = "#646464";
export const NASTYGREEN = "#73b543";
export const NICEBLUE = "#1b75bc";
export const PINK = "#f2f2f2";
export const GUNMETAL = "#4d4f5c";
export const WHITE = "#ffffff";
export const GREY = "#808080";
export const GRAY = "#a0a0a0";
export const LIGHTGREY = "#edf0f0";
