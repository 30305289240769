import React from "react";
import * as firebase from "firebase/app";

import {
  WrapperExtended,
  FormWrapper,
  FormTitle,
  Paragraph,
  ApprovalButton
} from "../styles/user-approval-form";
import { capitalize } from "../utility/helper";
import { STATUS_TYPES } from "../utility/constants";

export default function ApprovalForm({ match }) {
  const [referral, setReferral] = React.useState(null);
  const [approving, setApproving] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const getReferral = firebase.functions().httpsCallable("getReferral");
  const approveReferral = firebase.functions().httpsCallable("approveReferral");

  const onSubmit = async event => {
    event.preventDefault();
    setApproving(true);
    await approveReferral({
      id: referral.id,
      loanOfficerId: referral.loanOfficer.id,
      approvalType: "email",
      status: STATUS_TYPES.CLIENT_APPROVED,
      approvedOn: firebase.firestore.Timestamp.fromDate(new Date())
    });
    setDone(true);
    setApproving(false);
  };

  React.useEffect(() => {
    const fetchName = async () => {
      if (match.params.id) {
        const { data } = await getReferral({ id: match.params.id });
        setReferral(data);
        if (data.status !== STATUS_TYPES.WAITING_FOR_APPROVAL) {
          setDone(true);
        }
      }
    };
    fetchName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <WrapperExtended
      extraStyles={`
        max-width: 500px;
        margin: 0 auto;
      `}
    >
      <FormWrapper onSubmit={onSubmit}>
        <FormTitle>
          {done ? "Thank You " : "Hello "}
          {referral && referral.applicant
            ? `${capitalize(referral.applicant.firstName)},`
            : ""}
        </FormTitle>
        {done ? (
          <Paragraph>
            Thank you for your approval,{" "}
            {referral && referral.loanOfficer
              ? `${capitalize(referral.loanOfficer.firstName)} ${capitalize(
                  referral.loanOfficer.lastName
                )} `
              : "your loan officer "}
            will be notified about this and you will recieve a Quote Proposal
            shortly.
          </Paragraph>
        ) : (
          <Paragraph>
            {referral && referral.loanOfficer
              ? `${referral.loanOfficer.firstName} ${referral.loanOfficer.lastName} `
              : "Your loan officer "}
            has requested permission to share your information with KoverageOne
            so that we can provide you with a competitive quote on your
            homeowner’s insurance. <br />
            <br />
            By approving this request, we’ll get a quote from the top insurance
            providers in your area and send you the best priced proposal that we
            can.
          </Paragraph>
        )}
        {referral && done ? null : (
          <ApprovalButton
            disabled={
              approving ||
              done ||
              !referral ||
              !referral.applicant ||
              !referral.applicant.firstName ||
              referral.status !== STATUS_TYPES.WAITING_FOR_APPROVAL
            }
          >
            {!referral
              ? "Loading..."
              : referral.status === STATUS_TYPES.WAITING_FOR_APPROVAL
              ? "Approve Request"
              : "Already Approved"}
          </ApprovalButton>
        )}
      </FormWrapper>
    </WrapperExtended>
  );
}
