import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import "./App.css";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import UserForm from "./components/user-form";
import PastReferrals from "./components/referrals";
import ThankYou from "./components/thank-you";
import ApprovalForm from "./components/approval-form";
import UserApprovalForm from "./components/user-approval-form";
import UserDetailsForm from "./components/user-details-form";
import Hero from "./components/hero";
import Header from "./components/header";
import RouteContainer from "./styles/route-container";
import ApprovedDetails from "./components/approved-details";
import NotFound from "./components/not-found";
import DetailsNeeded from "./components/details-needed";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        global.AUTH.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <Router>
      <div className='App'>
        <Header />
        <Hero />
        <RouteContainer>
          <Switch>
            <Route exact path='/login' component={Login} />
            <PrivateRoute exact path='/' component={Dashboard} />
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/details/:id' component={UserForm} />
            <Route exact path='/approve/:id' component={UserApprovalForm} />
            <PrivateRoute
              exact
              path='/approved-details/:id'
              component={ApprovedDetails}
            />
            <PrivateRoute
              exact
              path='/details-needed/:id'
              component={DetailsNeeded}
            />
            <Route exact path='/details-form/:id' component={UserDetailsForm} />
            <PrivateRoute exact path='/referrals' component={PastReferrals} />
            <Route exact path='/thankyou' component={ThankYou} />
            <PrivateRoute exact path='/approval' component={ApprovalForm} />
            <Route component={NotFound} />
          </Switch>
        </RouteContainer>
      </div>
    </Router>
  );
}

export default App;

// tRWVpvKkMuJEXxRF0Ec9
