import React from "react";
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import * as firebase from "firebase/app";

import "react-datepicker/dist/react-datepicker.css";

import MainWrapper from "../components/main-wrapper";
import moment from "moment";
import { US_STATES, STATUS_TYPES } from "../utility/constants";
import { getReferral, saveReferral } from "../utility/database";
import {
  AddressWrapper,
  AddressTitle,
  AddressContentBox,
  StreetWrapper,
  StateWrapper,
  InputWrapper,
  DatePickerWrapper,
  SubmitButton,
  UserFormWrapper,
  UserFormTitle,
  UserFormRow,
  UserFormLabel,
  ApplicantTitle,
  Error
} from "../styles/user-form";
import { BROWNISHGREY, DARK20, LIGHTGREY } from "../styles/colors";
import {
  ValidateAddressWrapper,
  ValidateButton,
  ButtonImage
} from "../styles/user-detail-form";
import images from "../utility/images";

const MIN_DOB_DATE = new Date(1939, 0, 1);
const MAX_DOB_DATE = new Date(2002, 0, 1);
const getStateObject = state =>
  US_STATES.filter(({ value }) => value === state);

function Address({
  street,
  city,
  state,
  zip,
  changeAddress,
  title,
  setAddressErrors
}) {
  const changeInput = fieldName => event => {
    if (event.target.value) {
      changeAddress(fieldName)(event.target.value);
    }
  };

  const [selectedState, setSelectedState] = React.useState(
    getStateObject(state)
  );

  const onChangeState = values => {
    const value = values[0].value;
    if (value) {
      changeAddress("state")(value);
    }
  };

  const validate = async e => {
    e.preventDefault();
    // // const result = await getValidatedAddress({ street, city, state, zip });
    // if (!result) {
    //   setAddressErrors(`${title} is not valid!`);
    // }
  };

  React.useEffect(() => {
    if (state) {
      const selectedState = getStateObject(state);
      setSelectedState(selectedState);
    }
  }, [state]);

  return (
    <AddressWrapper>
      <AddressTitle>{title}</AddressTitle>
      <AddressContentBox>
        <StreetWrapper>
          <InputWrapper
            placeholder="Street"
            value={street}
            onChange={changeInput("street")}
          />
        </StreetWrapper>
        <InputWrapper
          width="200px"
          placeholder="City"
          value={city}
          onChange={changeInput("city")}
        />
        <InputWrapper
          width="150px"
          placeholder="Zipcode"
          value={zip}
          onChange={changeInput("zip")}
        />
        <StateWrapper>
          <Select
            options={US_STATES}
            style={{
              width: "100%",
              borderRadius: 5,
              border: `1px solid ${DARK20}`,
              fontSize: 14,
              padding: "14px 24px",
              color: BROWNISHGREY
            }}
            placeholder="State"
            values={selectedState}
            onChange={onChangeState}
          />
        </StateWrapper>
        <ValidateAddressWrapper>
          <ValidateButton onClick={validate}>
            <ButtonImage src={images.APPROVED_CIRCLE} alt="validate" />
            Validate Address
          </ValidateButton>
        </ValidateAddressWrapper>
      </AddressContentBox>
    </AddressWrapper>
  );
}

function Applicant({
  firstName,
  lastName,
  dateOfBirth,
  title,
  changeInput,
  changeValue
}) {
  return (
    <React.Fragment>
      <UserFormRow>
        <ApplicantTitle>{title}</ApplicantTitle>
      </UserFormRow>
      <UserFormRow>
        <UserFormLabel>First Name</UserFormLabel>
        <InputWrapper
          width="400px"
          placeholder="First Name"
          value={firstName}
          onChange={changeInput("firstName")}
        />
      </UserFormRow>
      <UserFormRow>
        <UserFormLabel>Last Name</UserFormLabel>
        <InputWrapper
          width="400px"
          placeholder="Last Name"
          value={lastName}
          onChange={changeInput("lastName")}
        />
      </UserFormRow>
      <UserFormRow>
        <UserFormLabel>DOB</UserFormLabel>
        <DatePickerWrapper width="400px">
          <DatePicker
            placeholderText="DOB"
            selected={dateOfBirth ? new Date(dateOfBirth) : dateOfBirth}
            onChange={changeValue("dateOfBirth")}
            minDate={MIN_DOB_DATE}
            maxDate={MAX_DOB_DATE}
            showYearDropdown
          />
        </DatePickerWrapper>
      </UserFormRow>
    </React.Fragment>
  );
}

function UserForm({ match, history }) {
  const [user, setUser] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const getValidatedAddress = firebase
    .functions()
    .httpsCallable("validateAddress");

  const changeInput = fieldName => event => {
    const value = event.target.value;
    setUser({ ...user, [fieldName]: value });
  };

  const changeNestedInput = parent => fieldName => event => {
    const value = event.target.value;
    setUser({ ...user, [parent]: { ...user[parent], [fieldName]: value } });
  };

  const changeNestedValue = parent => fieldName => value => {
    setUser({ ...user, [parent]: { ...user[parent], [fieldName]: value } });
  };

  const changeAddress = type => fieldName => value => {
    setUser({ ...user, [type]: { ...user[type], [fieldName]: value } });
  };

  const massageData = data => {
    const errors = [];
    const {
      borrower,
      coBorrower = { firstName: "", lastName: "", dateOfBirth: null },
      address,
      previousAddress = null,
      loanAmount
    } = data;
    let currentAddress = data.currentAddress || previousAddress;
    if (!borrower.firstName) errors.push("Applicant First Name is missing");
    if (!borrower.lastName) errors.push("Applicant Last Name is missing");
    if (!borrower.dateOfBirth) errors.push("Applicant DOB is missing");
    if (
      (coBorrower.lastName || coBorrower.dateOfBirth) &&
      !coBorrower.firstName
    )
      errors.push("Co Applicant First Name is missing");
    if (
      (coBorrower.firstName || coBorrower.dateOfBirth) &&
      !coBorrower.lastName
    )
      errors.push("Co Applicant Last Name is missing");
    if (
      (coBorrower.firstName || coBorrower.lastName) &&
      !coBorrower.dateOfBirth
    )
      errors.push("Co Applicant DOB is missing");
    if (!borrower.occupation) errors.push("Applicant Profession is missing");
    if (!loanAmount) errors.push("Loan Amount is missing");
    if (!currentAddress || !currentAddress.street || !currentAddress.zip)
      errors.push("Current Address is incomplete");
    if (!address || !address.street || !address.zip)
      errors.push("Property Address is incomplete");
    if (
      currentAddress &&
      currentAddress.zip &&
      currentAddress.zip.length !== 5
    ) {
      errors.push("Current Address zipcode is invalid");
    }
    if (address && address.zip && address.zip.length !== 5) {
      errors.push("Property Address zipcode is invalid");
    }
    if (errors.length) {
      return { success: false, errors };
    }

    let status = data.status;
    let submitted = false;
    if (
      errors.length === 0 &&
      (status === STATUS_TYPES.CLIENT_APPROVED ||
        status === STATUS_TYPES.DATA_NEEDED)
    ) {
      status = STATUS_TYPES.SUBMITTED_TO_UNDERWRITING;
      submitted = true;
    }
    const submission = {
      borrower: {
        ...borrower,
        dateOfBirth: moment(borrower.dateOfBirth).format("MM/DD/YYYY")
      },
      address: {
        ...address,
        state: address.state || "CT"
      },
      currentAddress: {
        ...currentAddress,
        state: currentAddress.state || "CT"
      },
      loanAmount,
      submittedOn: firebase.firestore.Timestamp.fromDate(new Date()),
      submitted,
      status
    };
    if (coBorrower.firstName) {
      submission.coBorrower = {
        ...coBorrower,
        dateOfBirth: moment(coBorrower.dateOfBirth).format("MM/DD/YYYY")
      };
    }
    if (submission.borrower.dateOfBirth) {
      delete submission.borrower.dateOfBirth;
    }
    if (submission.coBorrower && submission.coBorrower.dateOfBirth) {
      delete submission.coBorrower.dateOfBirth;
    }
    return { success: true, submission };
  };

  const onSubmit = async event => {
    event.preventDefault();
    setSaving(true);
    const currentUser = global.AUTH.currentUser;
    if (currentUser) {
      const { email } = currentUser;
      const { success, errors = [], submission: referral = null } = massageData(
        user
      );
      if (success) {
        setErrors([]);
        referral.id = user.id;
        await saveReferral(email, referral);
        history.replace("/thankyou");
      } else {
        setErrors(errors);
      }
    }
    setSaving(false);
  };

  React.useEffect(() => {
    const fetchName = async () => {
      const currentUser = global.AUTH.currentUser;
      if (currentUser && match.params.id) {
        const { email } = currentUser;
        const data = await getReferral(email, match.params.id);
        setUser(data);
      }
    };
    setTimeout(fetchName, 1000);
  }, [match.params.id]);

  const {
    borrower = {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      occupation: ""
    },
    coBorrower = {
      firstName: "",
      lastName: "",
      dateOfBirth: null
    },
    currentAddress = { street: "", state: "CT", zip: "", city: "" },
    address = { street: "", state: "CT", zip: "", city: "" },
    loanAmount
  } = user;
  let previousAddress = user.previousAddress
    ? { ...user.previousAddress }
    : { ...currentAddress };
  return (
    <MainWrapper
      mobileStyles={{
        backgroundColor: LIGHTGREY,
        top: 0,
        maxWidth: "100%",
        borderRadius: 0,
        padding: "25px 40px",
        boxShadow: "none"
      }}
    >
      <UserFormWrapper onSubmit={onSubmit}>
        <UserFormTitle>Continue Form</UserFormTitle>
        <Applicant
          {...borrower}
          changeInput={changeNestedInput("borrower")}
          changeValue={changeNestedValue("borrower")}
          title="Applicant"
        />
        <UserFormRow>
          <UserFormLabel>Profession</UserFormLabel>
          <InputWrapper
            width="400px"
            placeholder="Profession"
            value={borrower.occupation}
            onChange={changeNestedInput("borrower")("occupation")}
          />
        </UserFormRow>
        <UserFormRow marginMedium>
          <UserFormLabel>Loan Amount</UserFormLabel>
          <InputWrapper
            width="400px"
            margin="0 20px 0 0"
            placeholder="Loan Amount"
            value={loanAmount}
            onChange={changeInput("loanAmount")}
          />
        </UserFormRow>
        <Applicant
          {...coBorrower}
          changeInput={changeNestedInput("coBorrower")}
          changeValue={changeNestedValue("coBorrower")}
          title="Co-Applicant"
        />
        <Address
          {...address}
          changeAddress={changeAddress("address")}
          title="Property Address"
          setAddressErrors={err => setErrors([...errors, err])}
        />
        <Address
          {...previousAddress}
          changeAddress={changeAddress("currentAddress")}
          title="Current Address"
          setAddressErrors={err => setErrors([...errors, err])}
        />
        {errors && errors.length
          ? errors.map(error => <Error key={error}>{error}</Error>)
          : null}
        <SubmitButton disabled={saving}>Submit</SubmitButton>
      </UserFormWrapper>
    </MainWrapper>
  );
}

export default withRouter(UserForm);
