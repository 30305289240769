import styled from "styled-components";
import FlexBox from "./flexbox";
import { WHITE, NASTYGREEN } from "./colors";

const MOBILE = 425;

export const InfoWrapper = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  padding: 0;
  max-width: 350px;
  align-items: center;
  @media (max-width: ${MOBILE}px) {
    max-width: 280px;
    padding: 0;
  }
`;

export const ModalTitle = styled(FlexBox)`
  width: 100%;
  color: ${WHITE};
  background-color: ${NASTYGREEN};
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 20px;
  line-height: 25px
  font-weight: 400;
  margin: 0 10px;
  @media (max-width: ${MOBILE}px) {
    font-size: 16px;
  }
`;

export const ModalContent = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 12px 30px;
  text-align: center;
  @media (max-width: ${MOBILE}px) {
    font-size: 14px;
  }
`;

export const Icon = styled.img`
  flex: 0 0 auto;
  height: ${({ height = "24px" }) => height};
  width: ${({ width = "24px" }) => width};
  object-fit: contain;
`;
