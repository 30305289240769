import styled from "styled-components";
import FlexBox from "./flexbox";
import Button from "./button";
import Typography from "./typography";
import {
  PALEGREYTWO,
  BROWNISHGREY,
  NASTYGREEN,
  GUNMETAL,
  NICEBLUE,
  PINK
} from "./colors";
import { MOBILE, SMALLDESKTOP } from "./constants";

export const DashboardWrapper = styled(FlexBox)`
  @media (max-width: ${SMALLDESKTOP}px) {
    flex-flow: column nowrap;
  }
`;

export const DashboardActions = styled(FlexBox)`
  flex: 0 0 auto;
  flex-flow: column nowrap;
  margin-right: 80px;
  @media (max-width: ${SMALLDESKTOP}px) {
    margin: 0 0 15px 0;
    align-items: center;
  }
  @media (max-width: ${MOBILE}px) {
    & > button {
      width: 100%;
    }
  }
`;

export const NewReferralButton = styled(Button)`
  background-color: ${NASTYGREEN};
  padding: 16px;
  width: 250px;
  color: white;
  margin-bottom: 10px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const PastReferralButton = styled(Button)`
  padding: 16px;
  width: 250px;
  margin-top: 10px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const DashboardReferralView = styled.div`
  flex: 1;
`;

export const DashboardReferralTopContent = styled.div`
  padding: 14px 20px;
  color: ${NICEBLUE};
  background-color: ${PINK};
  border-radius: 5px;
  @media (max-width: ${MOBILE}px) {
    text-align: center;
  }
`;

export const DashboardReferralTopLoader = styled.div`
  padding: 20px;
  color: ${BROWNISHGREY};
  text-align: ${({ textAlign = "left" }) => textAlign};
  font-style: italic;
  @media (max-width: ${MOBILE}px) {
    padding: 20px 0;
    text-align: left;
  }
`;

export const DashboardNoReferralTopContent = styled.div`
  padding: 20px;
  text-align: ${({ textAlign = "left" }) => textAlign};
  color: ${BROWNISHGREY};
  @media (max-width: ${MOBILE}px) {
    padding: 20px 0;
    text-align: left;
  }
`;

export const DashboardOpenReferralsContentBox = styled.div`
  max-height: 350px;
  overflow: auto;
  margin-top: 10px;
`;

export const ReferralWrapper = styled(FlexBox)`
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${PALEGREYTWO};
  &:last-child {
    border: none;
  }
  @media (max-width: ${MOBILE}px) {
    padding: 15px 0;
  }
`;

export const HeaderWrapper = styled(FlexBox)`
  align-items: center;
`;

export const StatusIcon = styled.img`
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  object-fit: contain;
  cursor: pointer;
`;

export const ReferralDetails = styled(FlexBox)`
  flex: 1;
  flex-wrap: wrap;
  margin: 0 20px;
  @media (max-width: ${MOBILE}px) {
    margin: 0 10px;
  }
  color: ${GUNMETAL};
`;

export const HeaderDetails = styled(FlexBox)`
  flex: 1;
  flex-wrap: wrap;
  font-weight: bold;
  color: ${NICEBLUE};
  padding: 0;
  padding-left: 30px;
`;

export const Header = styled.span`
  flex: 1;
  font-size: 14px;
`;

export const ReferralStatus = styled(Typography)`
  flex: 1;
  color: ${GUNMETAL};
  font-size: 16px;
`;

export const ReferralName = styled(Typography)`
  flex: 1;
  color: ${GUNMETAL};
  font-size: 16px;
`;

export const ReferralDate = styled(Typography)`
  flex: 1;
  color: ${GUNMETAL};
  font-size: 16px;
`;

export const ReferralContiueButton = styled(Button)`
  flex: 0 0 auto;
  border: none;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 24px;
  border-radius: 1000px;
  background-color: ${NASTYGREEN};
  color: white;
  text-align: center;
  width: 110px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

export const StatusText = styled(Typography)`
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 24px;
  border-radius: 1000px;
  background-color: rgba(115, 181, 67, 0.2);
  color: rgba(0, 0, 0, 0.66);
  text-align: center;
  @media (max-width: ${MOBILE}px) {
    padding: 10px 24px;
  }
`;

export const StatusWrapper = styled.div`
  width: 110px;
  text-align: center;
`;
