import React from "react";
import * as firebase from "firebase/app";
import DatePicker from "react-datepicker";
import {
  WrapperExtended,
  DetailsContainer,
  Icon,
  DetailTitle,
  DetailRow,
  DetailLabel,
  Detail,
  TitleContainer,
  TypographyWrapper,
  TypographyTitle,
  TypographySubTitle,
  Loader,
  DatePickerWrapper,
  CalendarIcon,
  RadioContainer,
  Radio,
  RadioLabel,
  InputCheck,
  TextArea,
  ButtonWrapper,
  SubmitButton
} from "../styles/details-needed";
import { capitalize } from "../utility/helper";
import images from "../utility/images";
import { saveReferral } from "../utility/database";
import { STATUS_TYPES } from "../utility/constants";

const MIN_CLOSING_DATE = new Date(2019, 8, 1);
const MAX_CLOSING_DATE = new Date(20020, 0, 1);

const options = [
  {
    key: "Yes",
    label: "Yes"
  },
  {
    key: "No",
    label: "No"
  }
];

export default function DetailsNeeded({ match, history }) {
  const [referral, setReferral] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const getReferral = firebase.functions().httpsCallable("getReferral");

  React.useEffect(() => {
    const fetchName = async () => {
      if (match.params.id) {
        const { data } = await getReferral({ id: match.params.id });
        data.collectOnClose = "No";
        data.closingDate = MIN_CLOSING_DATE;
        data.mortgageClause = "";
        setReferral(data);
      }
    };
    fetchName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const onSubmit = async () => {
    setSaving(true);
    const currentUser = global.AUTH.currentUser;

    if (currentUser && referral.mortgageClause) {
      await saveReferral(currentUser.email, {
        ...referral,
        status: STATUS_TYPES.QUOTE_AVAILABLE
      });
      setSaving(false);
      history.replace("/");
    } else {
      setSaving(false);
    }
  };

  const changeValue = fieldName => value => {
    setReferral({
      ...referral,
      [fieldName]: value
    });
  };

  return (
    <WrapperExtended
      extraStyles={`
        max-width: 1000px;
        margin: 0 auto;
      `}
    >
      {!referral ? (
        <Loader>Fetching details...</Loader>
      ) : (
        <>
          <TitleContainer>
            <Icon src={images.WAITING3X} alt="info" />
            <TypographyWrapper>
              <TypographyTitle>Data Needed</TypographyTitle>
              <TypographySubTitle>
                {capitalize(referral.applicant.firstName)}{" "}
                {capitalize(referral.applicant.lastName)}
              </TypographySubTitle>
            </TypographyWrapper>
          </TitleContainer>
          <DetailsContainer>
            <DetailTitle>Please provide the following:</DetailTitle>
            <DetailRow>
              <DetailLabel>Tentative closing date : </DetailLabel>
              <Detail>
                <DatePickerWrapper width="110px">
                  <DatePicker
                    placeholderText="Closing Date"
                    selected={
                      referral.closingDate
                        ? new Date(referral.closingDate)
                        : referral.closingDate
                    }
                    onChange={changeValue("closingDate")}
                    minDate={MIN_CLOSING_DATE}
                    maxDate={MAX_CLOSING_DATE}
                    showYearDropdown
                  />
                </DatePickerWrapper>
                <CalendarIcon src={images.CALENDAR} alt="calendar" />
              </Detail>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Collect at Closing : </DetailLabel>
              <Detail>
                <RadioContainer>
                  {options.map((option, index) => (
                    <Radio
                      key={index}
                      onClick={() => changeValue("collectOnClose")(option.key)}
                    >
                      <InputCheck
                        name={option.key}
                        id={`${option.key}`}
                        type="radio"
                        checked={referral.collectOnClose === option.key}
                        required={true}
                        readOnly
                      />
                      <RadioLabel htmlFor={`${option.key}`}>
                        {option.label}
                      </RadioLabel>
                    </Radio>
                  ))}
                </RadioContainer>
              </Detail>
            </DetailRow>
            <DetailRow direction="column" defaultAlign>
              <DetailLabel>Mortgage Clause : </DetailLabel>
              <TextArea
                rows="5"
                value={referral.mortgageClause}
                onChange={e => changeValue("mortgageClause")(e.target.value)}
              />
            </DetailRow>
            <DetailRow>
              <ButtonWrapper>
                <SubmitButton onClick={onSubmit} disabled={saving}>
                  Submit
                </SubmitButton>
              </ButtonWrapper>
            </DetailRow>
          </DetailsContainer>
        </>
      )}
    </WrapperExtended>
  );
}
