import styled from "styled-components"

const FlexBox = styled.div`
	display: flex;
	flex-flow: ${({ direction = "row", wrap = "nowrap" }) => `${direction} ${wrap}`};
	align-items: ${({ alignItems = "stretch" }) => alignItems};
	justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
	padding: ${({ padding = "0" }) => padding};
	margin: ${({ margin = "0" }) => margin};
	width: ${({ width = "auto" }) => width};
	opacity: ${({ opacity = "1" }) => opacity};
`

export default FlexBox