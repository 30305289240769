import styled, { keyframes } from "styled-components";
import { WHITE } from "./colors";

const moveUp = keyframes`
0% {
	transform: translateY(-25%);
}

100% {
	transform: translateY(0);
}`;

const moveDown = keyframes`
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-25%);
    }
`;

const appear = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const disappear = keyframes`
	0% { opacity: 1; }
	100% { opacity: 0; }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  &.in {
    animation: ${appear} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  &.out {
    animation: ${disappear} 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
`;

export const ModalBodyContainer = styled.div`
  background-color: ${WHITE};
  position: relative;
  opacity: ${({ opacity = "1" }) => opacity};
  &.in {
    animation: ${moveUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  &.out {
    animation: ${moveDown} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  ${({ extraStyle = "" }) => extraStyle}
`;
