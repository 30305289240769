import styled from "styled-components";
import { TABLET, MOBILE, IPHONEX } from "./constants";

const StyledMainWrapper = styled.div`
  min-height: 500px;
  margin: 0 9vw;
  background: white;
  box-shadow: 0px 6px 30px 0px rgba(26, 26, 26, 0.18);
  padding: 40px;
  border-radius: 10px;
  position: relative;
  top: -80px;
  ${({ extraStyles = "" }) => extraStyles};
  @media (max-width: ${TABLET}px) {
    max-width: 90vw;
    margin: 0 auto;
  }
  @media (max-width: ${MOBILE}px) {
    max-width: 85vw;
    margin: 0 auto;
    padding: 40px 20px;
    ${({ mobileStyles = "" }) => mobileStyles};
  }
  ${IPHONEX} {
    padding: 40px 20px;
    ${({ mobileStyles = "" }) => mobileStyles};
  }
`;

export default StyledMainWrapper;
