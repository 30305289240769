import React from "react";
import * as firebase from "firebase/app";
import Switch from "react-switch";
import MainWrapper from "../components/main-wrapper";
import { validateEmail } from "../utility/helper";
import { saveReferral, getUserData } from "../utility/database";
import IMAGES from "../utility/images";
import {
  ApprovalFormWrapper,
  RequestApprovalTitle,
  RequestApprovalFormRow,
  RequestApprovalFormLabel,
  InputFieldWrapper,
  PhoneFieldWrapper,
  PhonePrefix,
  ActionWrapper,
  SendButton,
  SentButton,
  CheckImage,
  Message,
  TitleContainer
} from "../styles/approval-form";
import { NICEBLUE, NASTYGREEN } from "../styles/colors";
import { STATUS_TYPES } from "../utility/constants";

const FORM_TYPES = {
  REQUEST_APPROVAL: "REQUEST_APPROVAL",
  SEND_FORM: "SEND_FORM"
};

export default function ApprovalForm() {
  const sendApprovalMail = firebase
    .functions()
    .httpsCallable("sendApprovalEmail");
  const sendApprovalText = firebase
    .functions()
    .httpsCallable("sendApprovalText");
  const [user, setUser] = React.useState({
    lastName: "",
    firstName: "",
    email: "",
    phone: ""
  });
  const [sentMail, setSentMail] = React.useState(false);
  const [sentText, setSentText] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [formType, setFormType] = React.useState(FORM_TYPES.REQUEST_APPROVAL);
  const changeInput = fieldName => event => {
    const value = event.target.value;
    setUser({ ...user, [fieldName]: value });
  };

  const changePhone = event => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setUser({ ...user, phone: value });
    }
  };

  const changeFormType = () => {
    let newFormType = FORM_TYPES.REQUEST_APPROVAL;
    if (formType === FORM_TYPES.REQUEST_APPROVAL) {
      newFormType = FORM_TYPES.SEND_FORM;
    }
    setFormType(newFormType);
  };

  const { firstName, lastName, email, phone } = user;

  const onSubmit = async event => {
    event.preventDefault();
  };

  const onSendMail = async event => {
    event.preventDefault();
    const currentUser = global.AUTH.currentUser;
    const status =
      formType === FORM_TYPES.SEND_FORM
        ? STATUS_TYPES.WAITING_FOR_CLIENT_INFO
        : STATUS_TYPES.WAITING_FOR_APPROVAL;
    if (currentUser) {
      setSending(true);
      const referral = {
        ...user,
        approvalType: "",
        borrower: { firstName, lastName, email, cellPhone: phone },
        submitted: false,
        status
      };
      if (!referral.id) {
        const data = await saveReferral(currentUser.email, referral);
        referral.id = data.id;
      }
      const loanOfficer = await getUserData(currentUser.email);
      await sendApprovalMail({
        loanOfficer,
        referral,
        sendForm: formType === FORM_TYPES.SEND_FORM
      });
      setUser({ ...user, id: referral.id });
      setSentMail(true);
      setSending(false);
    }
  };

  const onSendText = async event => {
    event.preventDefault();
    const currentUser = global.AUTH.currentUser;
    const status =
      formType === FORM_TYPES.SEND_FORM
        ? STATUS_TYPES.WAITING_FOR_CLIENT_INFO
        : STATUS_TYPES.WAITING_FOR_APPROVAL;
    if (currentUser) {
      setSending(true);
      const referral = {
        ...user,
        approvalType: "",
        submitted: false,
        borrower: { firstName, lastName, email, cellPhone: phone },
        status
      };
      if (!referral.id) {
        const data = await saveReferral(currentUser.email, referral);
        referral.id = data.id;
      }
      const loanOfficer = await getUserData(currentUser.email);
      await sendApprovalText({
        loanOfficer,
        referral: { ...referral, phone: `1${referral.phone}` },
        sendForm: formType === FORM_TYPES.SEND_FORM
      });
      setUser({ ...user, id: referral.id });
      setSentText(true);
      setSending(false);
    }
  };

  let disclaimerText =
    "Customer will receive a request to approve that you share their information and you will be prompted to fill out the residence information.";

  if (formType === FORM_TYPES.SEND_FORM) {
    disclaimerText =
      "Customer will receive a form to share their personal and residential information";
  }

  return (
    <MainWrapper
      extraStyles={`
        max-width: 500px;
        margin: 0 auto;
      `}
    >
      <ApprovalFormWrapper onSubmit={onSubmit}>
        <TitleContainer>
          <RequestApprovalTitle
            disabled={formType === FORM_TYPES.SEND_FORM}
            mr="10px"
          >
            Request Approval
          </RequestApprovalTitle>
          <Switch
            onChange={changeFormType}
            checked={formType === FORM_TYPES.SEND_FORM}
            onColor={NASTYGREEN}
            offColor={NICEBLUE}
            uncheckedIcon={false}
            checkedIcon={false}
            height={25}
            width={50}
          />
          <RequestApprovalTitle
            disabled={formType === FORM_TYPES.REQUEST_APPROVAL}
            color={NASTYGREEN}
            ml="10px"
          >
            Send Form
          </RequestApprovalTitle>
        </TitleContainer>
        <RequestApprovalFormRow>
          <RequestApprovalFormLabel>First Name</RequestApprovalFormLabel>
          <InputFieldWrapper
            placeholder="First Name"
            value={firstName}
            onChange={changeInput("firstName")}
          />
        </RequestApprovalFormRow>
        <RequestApprovalFormRow>
          <RequestApprovalFormLabel>Last Name</RequestApprovalFormLabel>
          <InputFieldWrapper
            placeholder="Last Name"
            value={lastName}
            onChange={changeInput("lastName")}
          />
        </RequestApprovalFormRow>
        <RequestApprovalFormRow>
          <RequestApprovalFormLabel>Email</RequestApprovalFormLabel>
          <InputFieldWrapper
            placeholder="Email"
            value={email}
            onChange={changeInput("email")}
            disabled={sentMail}
          />
        </RequestApprovalFormRow>
        <RequestApprovalFormRow>
          <RequestApprovalFormLabel>Phone #</RequestApprovalFormLabel>
          <PhoneFieldWrapper>
            <PhonePrefix>+1</PhonePrefix>
            <InputFieldWrapper
              placeholder="Phone #"
              value={phone}
              onChange={changePhone}
              prefixed
              disabled={sentText}
            />
          </PhoneFieldWrapper>
        </RequestApprovalFormRow>
        <RequestApprovalFormRow>
          <ActionWrapper>
            {!sentMail ? (
              <SendButton
                margin="8px 20px 0 0"
                disabled={
                  sending ||
                  !firstName ||
                  !lastName ||
                  !validateEmail(email) ||
                  !phone ||
                  phone.length < 10
                }
                onClick={onSendMail}
              >
                Send Email
              </SendButton>
            ) : (
              <SentButton margin="10px 20px 0 0" onClick={onSendMail}>
                <CheckImage src={IMAGES.CHECKED} alt="checked" />
                Email Sent
              </SentButton>
            )}
            {!sentText ? (
              <SendButton
                margin="7.5px 0 0 20px"
                disabled={
                  sending ||
                  !firstName ||
                  !lastName ||
                  !phone ||
                  phone.length < 10 ||
                  !validateEmail(email)
                }
                onClick={onSendText}
              >
                Send Text
              </SendButton>
            ) : (
              <SentButton margin="10px 0 0 20px" onClick={onSendMail}>
                <CheckImage src={IMAGES.CHECKED} alt="checked" />
                Text Sent
              </SentButton>
            )}
          </ActionWrapper>
        </RequestApprovalFormRow>
        <Message>{disclaimerText}</Message>
      </ApprovalFormWrapper>
    </MainWrapper>
  );
}
